import React, { useEffect, useMemo, useState } from "react";

import {
  Badge,
  Box,
  Button,
  Divider,
  Editable,
  EditableInput,
  EditablePreview,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  Select,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { WarningTwoIcon } from "@chakra-ui/icons";
import { FaTrashAlt } from "react-icons/fa/index.esm.js";
import { FiEdit } from "react-icons/fi/index.esm.js";
import { MdEventRepeat } from "react-icons/md/index.esm.js";

import { deleteField } from "firebase/firestore";

import { getFieldAsDate } from "@snopro/common/firestore.js";
import { TypeOfCostsSchema, TypeOfDiscountsSchema } from "@snopro/common/models.js";
import { plural } from "@snopro/common/string-utils.js";
import { useNavigate } from "react-router-dom";

import { SummaryGridItem } from "@/components/order/SummaryGridItem.jsx";
import { useOrderModalContext } from "@/contexts/OrderModalContext.jsx";
import { useFetchOrderWithSkiers, useOrderSupportCalls } from "@/hooks/orderHooks.js";
import { useAdvancedMode } from "@/lib/advancedModeLib.js";
import dayjs from "@/lib/datetime.js";
import { useShowError } from "@/lib/error.js";
import { initOrderAndSkiersWithItems } from "@/lib/initialize-states.js";
import { useLocationConfig } from "@/lib/locationLib.js";
import { asPrice } from "@/lib/numbers.js";
import { sendOrderReceipt, updateOrderField, updateOrder } from "@/lib/order.api.js";
import { useReferrersByLocation } from "@/lib/referrers.js";
import {
  removeSkierItem,
  removeSkierProduct,
  removeSundries,
  skierUpdateSundries,
  updateSkierDiscount,
} from "@/lib/skier.api.js";

import ActivityList from "../common/ActivityList.jsx";
import DateBox from "../common/DateBox.jsx";
import EditableField from "../common/EditableField.jsx";
import EditableOrderStringField from "../common/EditableOrderStringField.jsx";
import JobCard from "../common/JobCard.jsx";
import CheckAllocate from "../flows/checks/CheckAllocate.jsx";
import CheckBootSelect from "../flows/checks/CheckBootSelect.jsx";
import CheckSkierInfo from "../flows/checks/CheckSkierInfo.jsx";
import DayAdjuster from "../flows/common/DayAdjuster.jsx";
import { OrderActionMenu } from "../order/OrderActionsMenu.jsx";
import { OrderAdjustments } from "../order/OrderAdjustments.jsx";
import AddItemModal from "./AddItemModal.jsx";
import EditSkierModal from "./EditSkierModal.jsx";
import NewOrderSupportCallModal from "./NewOrderSupportCallModal.jsx";
import PaymentsListModal from "./PaymentsListModal.jsx";
import SkiPassModal from "./SkiPassModal.jsx";

/**
 * @param {object} props
 * @param {string} props.orderId
 * @param {() => void} [props.closeModal]
 * @returns {JSX.Element}
 */
export default function Order({ orderId, closeModal }) {
  const { showError } = useShowError({ title: "Order Update Error" });
  const { data } = useFetchOrderWithSkiers({ orderId });
  const { supportCalls } = useOrderSupportCalls({ orderId });
  const [isSendingBookSummary, setIsSendingBookSummary] = useState(false);
  const { isAdvancedModeOn } = useAdvancedMode();

  const [order, setOrder] = useState(initOrderAndSkiersWithItems());
  const { inStore } = useLocationConfig(order?.location ?? "Queenstown");

  useEffect(() => {
    if (!data?.order) {
      setOrder(initOrderAndSkiersWithItems());
      return;
    }
    setOrder(data.order);
  }, [data]);
  const referrerOptions = useReferrersByLocation(order?.location);
  // it might not be necessary, it's just doing the optmistic UI update
  const handleInputChange = (name, value) => {
    if (order) {
      setOrder({ ...order, [name]: value });
    }
  };
  /**
   * @param {keyof TOrder} name
   * @param {*} value
   */
  const updateField = async (name, value) => {
    try {
      if (!order || !order.id) {
        throw new Error("Order is not set");
      }

      if (name == "location") {
        await updateOrder(
          order,
          {
            location: value,
            team: value,
          },
          "order-page",
        );
      } else {
        await updateOrderField(order, name, value, "order-page");
      }
    } catch (error) {
      if (data) {
        setOrder(data.order);
      }
      showError(error);
    }
  };

  const isOrderCompleted = data?.order.state === "completed";

  const navigate = useNavigate();
  const toast = useToast();

  const { setModalId } = useOrderModalContext();
  const [editing, setEditing] = useState("");

  const newSupportCallModal = useDisclosure();
  const paymentsModal = useDisclosure();

  /** @param {any|string} error */
  const showToastError = (error) =>
    toast({
      title: error.message ?? error,
      status: "error",
      duration: 2000,
      isClosable: true,
    });

  const removeItem = removeSkierItem.bind(null, order?.id ?? "");
  const removeProduct = removeSkierProduct.bind(null, order?.id ?? "");

  const referrersByGroup = useMemo(() => {
    const value = referrerOptions.reduce(
      (
        /** @type { {common: ReturnType<typeof useReferrersByLocation>; routes: ReturnType<typeof useReferrersByLocation>  } } */ acc,
        referrer,
      ) => {
        if (referrer.route) {
          acc.routes.push(referrer);
        } else {
          acc.common.push(referrer);
        }
        return acc;
      },
      {
        common: [],
        routes: [],
      },
    );
    value.common.sort((l, r) => l.name.localeCompare(r.name));
    value.routes.sort((l, r) => l.name.localeCompare(r.name));
    return value;
  }, [referrerOptions]);

  if (!data || !order) {
    return <></>;
  }

  function getLabel(state) {
    switch (state) {
      case "toSize":
        return "ALLOCATE";
      case "toDeliver":
        return inStore ? "FITTING" : "DELIVER";
      case "toCollect":
        return inStore ? "RETURN" : "COLLECT";
      default:
        return state.substring(2).toUpperCase();
    }
  }

  return (
    <>
      <Box my={4}>
        <HStack spacing={3}>
          {isOrderCompleted && (
            <Box>
              <Badge fontSize="lg" variant={"subtle"} colorScheme={"whatsapp"}>
                COMPLETED
              </Badge>
            </Box>
          )}
          <Heading>
            <HStack>
              {(order.previousOrder || order.referrer.name == "Repeat Client") && (
                <Icon boxSize={7} mr={2} color="brand.500" as={MdEventRepeat} />
              )}
              <EditableOrderStringField
                isDisabled={isOrderCompleted || order.state === "inactive"}
                fieldName="firstName"
                order={order}
                onChange={updateField}
                maxW={200}
                shouldTrimValueOnUpdate={true}
              />
              <EditableOrderStringField
                isDisabled={isOrderCompleted || order.state === "inactive"}
                fieldName="lastName"
                order={order}
                onChange={updateField}
                maxW={250}
                shouldTrimValueOnUpdate={true}
              />
              <Icon boxSize={3} as={FiEdit} position="relative" top="-14px" />
              {order.previousOrder && (
                <Button
                  color="gray.400"
                  variant="outline"
                  size="xs"
                  onClick={(e) => setModalId(order.previousOrder, Boolean(e.ctrlKey || e.shiftKey))}
                  ml={6}
                >
                  View previous booking
                </Button>
              )}
            </HStack>
          </Heading>
          <Box pr={12}>
            <OrderActionMenu
              order={order}
              onOrderDeleted={() => {
                if (closeModal) {
                  closeModal();
                  return;
                }
                navigate("/");
              }}
              actionsToShow={["duplicate", "delete"]}
            />
          </Box>
        </HStack>

        <HStack spacing={1}>
          <Tooltip
            label={
              <>
                <Text>
                  Booking time:{" "}
                  {dayjs.tz(getFieldAsDate(order.bookingTime)).format("DD/MM/YYYY HH:mm")}
                </Text>
                <Text>
                  Lead time:{" "}
                  {plural(
                    "hour",
                    dayjs(getFieldAsDate(order.deliverTime)).diff(
                      getFieldAsDate(order.bookingTime),
                      "hour",
                    ),
                    { ifZero: "less than an hour" },
                  )}
                </Text>
                {isAdvancedModeOn && <Text>{order.id}</Text>}
              </>
            }
            placement="auto"
            hasArrow
          >
            <Text
              onClick={(e) => {
                if (!e.ctrlKey && !e.shiftKey) {
                  return;
                }

                if (e.ctrlKey && e.shiftKey) {
                  setModalId(order.id, true);
                  return;
                }

                const propName = e.ctrlKey ? "id" : "lookupId";
                navigator.clipboard.writeText(order[propName]);
                toast({
                  title: `[${propName}] copied to clipboard`,
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                });
              }}
            >
              {order?.location} • Booked{" "}
              {dayjs.tz(getFieldAsDate(order.bookingTime)).format("D MMM YYYY")} (
              {dayjs.tz(getFieldAsDate(order.bookingTime)).fromNow()})
              <Text as="span">#{order?.lookupId}</Text>
              {order?.source === "sas1" && (
                <Text as="span" fontStyle={"italic"}>
                  {" "}
                  from SAS1
                </Text>
              )}
            </Text>
          </Tooltip>
        </HStack>
      </Box>
      <Grid gridTemplateColumns={"1.2fr 1fr 1fr 1fr"} gap={8}>
        <GridItem>
          <VStack spacing={2} alignItems="left">
            <HStack align="start">
              <DateBox
                date={getFieldAsDate(order.deliverTime)}
                includeTime={true}
                order={order}
                updateField="deliverTime"
              />

              <Box>
                {order?.deliveryLocked && <Badge colorScheme="orange">Delivery time locked!</Badge>}
                <EditableField
                  isDisabled={isOrderCompleted}
                  fieldName="addressDelivery"
                  value={order?.addressDelivery}
                  displayName="Deliver to"
                  displayType="textarea"
                  handleInput={handleInputChange}
                  handleUpdate={updateField}
                  location={order.location}
                />
              </Box>
            </HStack>

            <Box>
              <Text fontWeight="bold" fontSize={13}>
                {order?.skiDays} ski days
              </Text>
            </Box>

            <HStack align="start">
              <DateBox
                date={getFieldAsDate(order.collectTime)}
                includeTime={true}
                order={order}
                updateField="collectTime"
              />
              <Box>
                <EditableField
                  isDisabled={isOrderCompleted}
                  fieldName="addressCollection"
                  value={order?.addressCollection}
                  displayName="Collect from"
                  displayType="textarea"
                  handleInput={handleInputChange}
                  handleUpdate={updateField}
                  location={order.location}
                />
              </Box>
            </HStack>
          </VStack>
        </GridItem>
        <GridItem>
          <EditableField
            isDisabled={isOrderCompleted}
            fieldName="email"
            value={order?.email}
            displayName="Email"
            displayType="text"
            handleInput={handleInputChange}
            handleUpdate={updateField}
          />
          <EditableField
            isDisabled={isOrderCompleted}
            fieldName="phone"
            value={order?.phone}
            displayName="Phone Number"
            displayType="text"
            handleInput={handleInputChange}
            handleUpdate={updateField}
          />
          <VStack spacing={0} align="start" mb={4} w="fit-content">
            <Text fontSize={10} fontWeight="bold">
              Referrer
            </Text>
            {referrerOptions.length > 0 && (
              <Select
                isDisabled={isOrderCompleted}
                variant="unstyled"
                value={order.referrer.id}
                onChange={(e) => {
                  const referrer = referrerOptions.find((r) => r.id === e.target.value);
                  if (!referrer) {
                    showToastError("Referrer not found");
                    return;
                  }

                  updateField("referrer", {
                    id: referrer?.id ?? "",
                    name: referrer?.name ?? e.target.value,
                  });
                }}
              >
                <optgroup label={`Common`}>
                  {referrersByGroup.common.map(({ name, id }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </optgroup>
                <optgroup label={`Route based`}>
                  {referrersByGroup.routes.map(({ name, id }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </optgroup>
              </Select>
            )}
          </VStack>
          <EditableField
            isDisabled={isOrderCompleted}
            fieldName="roomNumber"
            value={order?.roomNumber}
            displayName="Room No."
            displayType="text"
            handleInput={handleInputChange}
            handleUpdate={updateField}
          />
        </GridItem>
        <GridItem>
          <VStack spacing={0} align="start" mb={4} w="fit-content">
            <Text fontSize={10} fontWeight="bold">
              Booking Status <WarningTwoIcon boxSize={3} />
            </Text>
            <Select
              variant="unstyled"
              value={order.state}
              onChange={(e) => updateField("state", e.target.value)}
            >
              <option value="toSize">Allocate</option>
              {!inStore && <option value="toPack">Pack</option>}
              <option value="toDeliver">{inStore ? "Fitting" : "Deliver"}</option>
              <option value="toCollect">{inStore ? "Return" : "Collect"}</option>
              <option value="completed">Completed</option>
              <option value="inactive">Inactive</option>
            </Select>
          </VStack>
          <VStack spacing={0} align="start" mb={4} w="fit-content">
            <Text fontSize={10} fontWeight="bold">
              Location
            </Text>
            <Select
              isDisabled={isOrderCompleted}
              variant="unstyled"
              value={order.location}
              onChange={(e) => updateField("location", e.target.value)}
            >
              {["Queenstown", "Wanaka", "Hilton"].map((location) => (
                <option key={location} value={location}>
                  {location}
                </option>
              ))}
            </Select>
          </VStack>
          {!["toSize", "toPack"].includes(order.state) && (
            <VStack spacing={0} align="start" mb={4} w="fit-content">
              <Text fontSize={10} fontWeight="bold">
                Responsible Team
              </Text>
              <Select
                isDisabled={isOrderCompleted}
                variant="unstyled"
                value={order.team}
                onChange={(e) => updateField("team", e.target.value)}
              >
                {["Queenstown", "Wanaka", "Hilton"].map((location) => (
                  <option key={location} value={location}>
                    {location}
                  </option>
                ))}
              </Select>
            </VStack>
          )}
          <EditableField
            isDisabled={isOrderCompleted}
            fieldName="comments"
            value={order?.comments}
            displayName="Customer Comments"
            displayType="textarea"
            handleInput={handleInputChange}
            handleUpdate={updateField}
          />
          <EditableField
            fieldName="commentsInternal"
            value={order?.commentsInternal}
            displayName="Internal Comments"
            displayType="textarea"
            handleInput={handleInputChange}
            handleUpdate={updateField}
          />
        </GridItem>
        <GridItem>
          <Text fontSize={10} fontWeight="bold" mb={-1}>
            Latest Activity
          </Text>
          <Box py={2} h="260px" overflow="auto" sx={{ "::-webkit-scrollbar": { display: "none" } }}>
            <ActivityList orderId={order.id} disableOpenOrder />
          </Box>
        </GridItem>
      </Grid>
      <Divider mt={8} borderColor="black" />
      <HStack w="100%" justify="center" spacing={6} mt="-4" mb="6">
        <Button colorScheme="brand" size="sm" onClick={newSupportCallModal.onOpen}>
          Create Support Call
        </Button>
        <Button colorScheme="brand" size="sm" onClick={paymentsModal.onOpen}>
          Payments
        </Button>
        <Button
          hidden={order.state === "inactive"}
          colorScheme="brand"
          size="sm"
          onClick={async () => {
            if (isSendingBookSummary) {
              return;
            }
            setIsSendingBookSummary(true);
            try {
              if (order.state === "inactive") {
                throw new Error("Cannot send summary for inactive order");
              }

              await sendOrderReceipt(order.id).catch(showError);
            } catch (error) {
              showError(error);
            } finally {
              setIsSendingBookSummary(false);
            }
          }}
          isLoading={isSendingBookSummary}
        >
          Send Summary
        </Button>
        {!isOrderCompleted && order.state !== "inactive" && (
          <Button
            colorScheme="brand"
            size="sm"
            onClick={() => {
              const type = order?.state.substring(2).toLowerCase();
              navigate(
                (type == "deliver" || type == "collect" ? "/drive/" : "/") + type + "/" + order?.id,
              );
              closeModal && closeModal();
            }}
          >
            Start job: {getLabel(order.state)}
          </Button>
        )}
      </HStack>
      <Grid
        templateColumns="6fr 6fr 1fr 6fr 2fr 2fr 2fr"
        alignItems="center"
        gap={2}
        columnGap={4}
        justifyItems="start"
        mb={6}
      >
        {order.skiers.map((skier, i) => (
          <React.Fragment key={skier.id}>
            <GridItem colSpan={7} w="100%" height="auto" pt={i > 0 ? 6 : 0}>
              {!skier.duplicateOf && i > 0 && <Divider borderColor="black" />}
            </GridItem>
            <GridItem>
              <HStack spacing={0}>
                {!isOrderCompleted && <EditSkierModal skier={skier} orderId={order.id} />}
                {!skier.duplicateOf && (
                  <>
                    <CheckSkierInfo skier={skier} showIsOutOfRange />
                  </>
                )}
              </HStack>
            </GridItem>
            <GridItem>
              {skier.packageType && (
                <CheckBootSelect skier={skier} order={order} showDIN={true} source="order-page" />
              )}
            </GridItem>
            <GridItem colSpan={3}>
              {skier.packageType ? (
                <CheckAllocate skier={skier} order={order} />
              ) : (
                <Text fontSize="sm">(no package added)</Text>
              )}
            </GridItem>
            {!isOrderCompleted && (
              <>
                <GridItem>
                  <AddItemModal skier={skier} order={order} />
                </GridItem>
                <GridItem justifySelf="center">
                  {editing == skier.id ? (
                    <Button size="xs" colorScheme="brand" onClick={() => setEditing("")}>
                      done
                    </Button>
                  ) : (
                    <Button size="xs" onClick={() => setEditing(skier.id)}>
                      edit items
                    </Button>
                  )}
                </GridItem>
              </>
            )}
            {editing == skier.id && (
              <OrderSkierItemsEditor
                key={`editor-${skier.id}`}
                order={order}
                skier={skier}
                removeItem={removeItem}
                removeProduct={removeProduct}
                showError={showToastError}
              />
            )}
            {editing != skier.id && skier && order && <ItemsSummary skier={skier} order={order} />}
          </React.Fragment>
        ))}

        <GridItem colSpan={7} w="100%" height="auto" pt={6}>
          <Divider borderColor="black" />
        </GridItem>
        {data.skiPassesTotal > 0 ? (
          <>
            <SummaryGridItem
              leftCellContent={isOrderCompleted ? undefined : <EditSkierModal orderId={order.id} />}
              middleCellContent={<Text isTruncated>Ski Pass Total</Text>}
              rightCellContent={asPrice(data.skiPassesTotal)}
            />
            <SummaryGridItem
              middleCellContent={<Text isTruncated>Rental Total</Text>}
              rightCellContent={asPrice(data.rentalsTotal)}
            />
          </>
        ) : (
          <SummaryGridItem
            leftCellContent={
              !isOrderCompleted && data.skiPassesTotal == 0 && <EditSkierModal orderId={order.id} />
            }
            middleCellContent={<Text isTruncated>Rental Total</Text>}
            rightCellContent={asPrice(order?.finalPrice)}
          />
        )}

        <SummaryGridItem
          middleCellContent={<Text isTruncated>Order Total</Text>}
          rightCellContent={asPrice(order?.finalPrice)}
        />
        <SummaryGridItem
          middleCellContent={<Text isTruncated>Paid</Text>}
          rightCellContent={asPrice(order?.paidAmount)}
        />
        <SummaryGridItem
          middleCellContent={
            <Text fontWeight="bold" isTruncated>
              Balance Due
            </Text>
          }
          rightCellContent={asPrice(order?.balance)}
        />
        <SummaryGridItem
          middleCellContent={
            <Text isTruncated fontStyle={"italic"}>
              incl. discount
            </Text>
          }
          rightCellContent={<Text fontStyle={"italic"}>{asPrice(order.totalDiscount * -1)}</Text>}
        />

        <GridItem colSpan={7} w="100%" height="auto">
          <Divider borderColor="black" />
        </GridItem>
      </Grid>
      <OrderAdjustments order={order} />
      <Divider mt={8} borderColor="black" />
      {supportCalls.length > 0 && (
        <>
          <Text fontWeight="bold" my={6}>
            Support calls:
          </Text>
          <HStack mb={6} spacing={6}>
            {supportCalls.map((supportCall) => (
              <JobCard
                key={supportCall.id}
                order={supportCall}
                type="support"
                isFaded={!supportCall.isActive}
                isTransfer={supportCall.location != order.location}
              />
            ))}
          </HStack>
        </>
      )}
      <NewOrderSupportCallModal
        isOpen={newSupportCallModal.isOpen}
        onClose={newSupportCallModal.onClose}
        order={order}
      />
      <PaymentsListModal
        order={order}
        onClose={paymentsModal.onClose}
        isOpen={paymentsModal.isOpen}
      />
    </>
  );
}

/**
 * @param {Object} props
 * @param {TOrderAndSkiersWithItems} props.order
 * @param {TSkierWitItems} props.skier
 */
function ItemsSummary({ order, skier }) {
  if (!order || !skier) {
    return null;
  }

  return (
    <>
      {skier.items.map((item) => {
        return (
          <React.Fragment key={item.id}>
            <GridItem minH={6} colSpan={3} />
            <GridItem fontSize="sm" alignSelf="flex-start">
              {item.type === "skipass" ? (
                <SkiPassModal skier={skier} orderId={order.id} text={item.description} />
              ) : (
                <Text ml={0} fontWeight="bold" isTruncated>
                  {item.description}
                </Text>
              )}
            </GridItem>
            <GridItem fontSize="sm" alignSelf="flex-start">
              {item.type === "rental" || item.costType === "insurance"
                ? asPrice(item.price.unitPriceDiscount)
                : "-"}
            </GridItem>
            <GridItem fontSize="sm" alignSelf="flex-start">
              {item.type === "rental" || item.costType === "insurance"
                ? plural("day", item.quantity)
                : "-"}
            </GridItem>
            <GridItem fontSize="sm" alignSelf="flex-start" justifySelf="flex-end">
              {asPrice(item.price.finalPrice)}
            </GridItem>
          </React.Fragment>
        );
      })}
      <React.Fragment key="total">
        <GridItem minH={6} colSpan={3} justifySelf="flex-end" alignSelf="flex-start"></GridItem>
        <GridItem fontSize="sm" alignSelf="flex-start"></GridItem>
        <GridItem fontSize="sm" alignSelf="flex-start"></GridItem>
        <GridItem fontSize="sm" alignSelf="flex-start"></GridItem>
        <GridItem fontSize="sm" alignSelf="flex-start" justifySelf="flex-end">
          <Text fontWeight={"bold"}>{asPrice(skier.finalPrice)}</Text>
        </GridItem>
      </React.Fragment>

      {skier.totalDiscount > 0 && (
        <React.Fragment key="discount">
          <GridItem minH={6} colSpan={3} justifySelf="flex-end" alignSelf="flex-start"></GridItem>
          <GridItem fontSize="sm" alignSelf="flex-start">
            <Text fontStyle={"italic"}>incl. discount</Text>
          </GridItem>
          <GridItem fontSize="sm" alignSelf="flex-start"></GridItem>
          <GridItem fontSize="sm" alignSelf="flex-start"></GridItem>
          <GridItem fontSize="sm" alignSelf="flex-start" justifySelf="flex-end">
            <Text fontStyle={"italic"}>{asPrice(skier.totalDiscount * -1)}</Text>
          </GridItem>
        </React.Fragment>
      )}
    </>
  );
}
/**
 * @param {Object} props
 * @param {TOrderAndSkiersWithItems} props.order
 * @param {TSkierWitItems} props.skier
 * @param {(skier:TSkier, itemType:TTypeOfCosts, id:string) => Promise<void>} props.removeItem
 * @param {(skierId:string, product:TSkierExtra) => void} props.removeProduct
 * @param {(errorMesage:string) => void} props.showError
 * */
function OrderSkierItemsEditor({ skier, order, removeProduct, removeItem, showError }) {
  return (
    <>
      {skier.items.map((i) => {
        if (i.isSundries) {
          return (
            <OrderSundriesItemEdit
              key={i.id}
              order={order}
              item={i}
              skier={skier}
              showError={showError}
              removeItem={(skierId, item) => {
                if (item.code) {
                  removeSundries(order.id, skierId, item.code).catch(showError);
                }
              }}
            />
          );
        }

        if (i.type === "product") {
          const product = skier.extras?.find((e) => e.code === i.code);
          if (!product) {
            return (
              <GridItem colSpan={7} key={i.id}>
                COULD NOT FIND PRODUCT
                {JSON.stringify(i)}
              </GridItem>
            );
          }
          return (
            <OrderItemEditsProduct
              key={i.id}
              skierId={skier.id}
              product={product}
              removeProduct={removeProduct}
              item={i}
            />
          );
        }

        if (i.type === "skipass") {
          return (
            <OrderItemSkiPassEditor
              key={i.id}
              order={order}
              skier={skier}
              isEdit={true}
              removeItem={removeItem}
              item={i}
            />
          );
        }

        const parsedType = TypeOfCostsSchema.safeParse(i.costType);
        if (!parsedType.success) {
          return (
            <GridItem colSpan={7} key={i.id}>
              COULD NOT PARSE ITEMS
              {JSON.stringify(i)}
            </GridItem>
          );
        }

        const type = parsedType.data;
        return (
          <OrderItemEdits
            key={i.id}
            order={order}
            skier={skier}
            name={i.description || i.code || i.shortName || i.costType || i.type || i.id}
            type={type}
            item={i}
            removeItem={removeItem}
            showError={showError}
          />
        );
      })}
      {/* TODO: this totals should be elsewhere or in another component */}
      <React.Fragment key="total">
        <GridItem minH={6} colSpan={3} justifySelf="flex-end" alignSelf="flex-start"></GridItem>
        <GridItem fontSize="sm" alignSelf="flex-start"></GridItem>
        <GridItem fontSize="sm" alignSelf="flex-start"></GridItem>
        <GridItem fontSize="sm" alignSelf="flex-start"></GridItem>
        <GridItem fontSize="sm" alignSelf="flex-start" justifySelf="flex-end">
          <Text fontWeight={"bold"}>{asPrice(skier.finalPrice)}</Text>
        </GridItem>
      </React.Fragment>

      {skier.totalDiscount > 0 && (
        <React.Fragment key="discount">
          <GridItem minH={6} colSpan={3} justifySelf="flex-end" alignSelf="flex-start"></GridItem>
          <GridItem fontSize="sm" alignSelf="flex-start">
            <Text fontStyle={"italic"}>incl. discount</Text>
          </GridItem>
          <GridItem fontSize="sm" alignSelf="flex-start"></GridItem>
          <GridItem fontSize="sm" alignSelf="flex-start"></GridItem>
          <GridItem fontSize="sm" alignSelf="flex-start" justifySelf="flex-end">
            <Text fontStyle={"italic"}>{asPrice(skier.totalDiscount * -1)}</Text>
          </GridItem>
        </React.Fragment>
      )}
    </>
  );
}

/**
 * @param {object} props
 * @param {TOrder} props.order
 * @param {TSkierWitItems} props.skier
 * @param {(skierId:string, item:TSkierItem) => void} props.removeItem
 * @param {TSkierItem} props.item
 * @param { (error) => void} props.showError
 
 */
function OrderSundriesItemEdit({ order, skier, removeItem, item, showError }) {
  return (
    <>
      <GridItem minH={6} colSpan={3} justifySelf="flex-end" alignSelf="flex-start">
        <Box overflow="hidden">
          <Icon as={FaTrashAlt} cursor="pointer" onClick={() => removeItem(skier.id, item)} />
        </Box>
      </GridItem>
      <GridItem fontSize="sm" alignSelf="flex-start">
        <Text ml={0} fontWeight="bold" isTruncated>
          {item.description ?? ""}
        </Text>
      </GridItem>

      <DayAdjuster
        adjustment={item.adjustmentDays ?? 0}
        order={order}
        hideDayAdjuster={item.type !== "rental"}
        hidePosition
        skierId={skier.id}
        skiDays={order.skiDays}
        item={item}
        skier={skier}
        onChangeDayAdjustment={async (days) => {
          skierUpdateSundries(order.id, skier.id, item.code ?? "", {
            adjustmentDays: days || deleteField(),
          }).catch(showError);
        }}
      />

      <GridItem fontSize="sm" alignSelf="flex-start">
        {item.type === "rental" && (
          <Select
            isDisabled={order.autoCalcDiscount !== false}
            value={(item.discountPercentage ?? 0).toString()}
            size="xs"
            onChange={async (e) => {
              const discountPercentage = Number(e.target.value);
              skierUpdateSundries(order.id, skier.id, item.code ?? "", {
                discountPercentage: discountPercentage || deleteField(),
              }).catch(showError);
            }}
          >
            <option value="0">discount</option>
            <option value="0.1">-10%</option>
            <option value="0.15">-15%</option>
            <option value="0.2">-20%</option>
            <option value="0.25">-25%</option>
            <option value="0.5">-50%</option>
            <option value="1">-100%</option>
          </Select>
        )}
      </GridItem>
    </>
  );
}

/**
 * @param {object} props
 * @param {TOrderAndSkiersWithItems} props.order
 * @param {TSkierWitItems} props.skier
 * @param {TTypeOfCosts} props.type
 * @param {(skier:TSkier, type:TTypeOfCosts, id:string) => Promise<void>} props.removeItem
 * @param {string} props.name
 * @param {TSkierItem} props.item
 * @param {(error:string) => void} props.showError
 */
function OrderItemEdits({ name, type, skier, removeItem, order, showError, item }) {
  return (
    <React.Fragment key={name}>
      <GridItem minH={6} colSpan={3} justifySelf="flex-end" alignSelf="flex-start">
        <Box overflow="hidden">
          <Icon
            as={FaTrashAlt}
            cursor="pointer"
            onClick={() => removeItem(skier, type, item.id).catch(showError)}
          />
        </Box>
      </GridItem>
      <GridItem fontSize="sm" alignSelf="flex-start">
        <Text ml={0} fontWeight="bold" isTruncated>
          {name}
        </Text>
      </GridItem>
      <DayAdjuster
        type={type}
        adjustment={skier.adjustments?.[type + "_days"]}
        position={skier.adjustments?.[type + "_pos"]}
        order={order}
        skierId={skier.id}
        skiDays={order.skiDays}
        item={item}
        skier={skier}
      />
      {TypeOfDiscountsSchema.safeParse(type).success ? (
        <GridItem fontSize="sm" alignSelf="flex-start">
          <Select
            isDisabled={order.autoCalcDiscount !== false}
            value={(skier.discountsPercentage?.[type] ?? 0).toString()}
            size="xs"
            onChange={async (e) => {
              try {
                await updateSkierDiscount(
                  order.id,
                  skier.id,
                  TypeOfDiscountsSchema.parse(type),
                  parseFloat(e.target.value),
                );
              } catch (/** @type {any} */ error) {
                showError("Could not update discount" + (error?.message ?? error));
              }
            }}
          >
            <option value="0">discount</option>
            <option value="0.1">-10%</option>
            <option value="0.15">-15%</option>
            <option value="0.2">-20%</option>
            <option value="0.25">-25%</option>
            <option value="0.5">-50%</option>
            <option value="1">-100%</option>
          </Select>
        </GridItem>
      ) : (
        <GridItem />
      )}
    </React.Fragment>
  );
}

/**
 * @param {object} props
 * @param {string} props.skierId
 * @param {TSkierExtra} props.product
 * @param {TSkierItem} props.item
 * @param {(skierId:string, product:TSkierExtra) => void} props.removeProduct
 */
function OrderItemEditsProduct({ product, skierId, removeProduct }) {
  return (
    <React.Fragment key={product.name}>
      <GridItem minH={6} colSpan={3} justifySelf="flex-end" alignSelf="flex-start">
        <Icon as={FaTrashAlt} onClick={() => removeProduct(skierId, product)} cursor="pointer" />
      </GridItem>
      <GridItem fontSize="sm" alignSelf="flex-start">
        <Text ml={0} fontWeight="bold" isTruncated>
          {product.name}
        </Text>
      </GridItem>
      <GridItem colSpan={2}></GridItem>
      <GridItem fontSize="sm" alignSelf="flex-start"></GridItem>
    </React.Fragment>
  );
}

/**
 * @param {Object} props
 * @param {TSkierWitItems} props.skier
 * @param {boolean} props.isEdit
 * @param {TOrderAndSkiersWithItems} props.order
 * @param {TSkierItem} props.item
 * @param {(skier:TSkier, type:TTypeOfCosts, id:string) => void} props.removeItem
 * @returns {JSX.Element}
 */
function OrderItemSkiPassEditor({ skier, isEdit, order, removeItem, item }) {
  if (!skier.skiPass || !order) {
    return <></>;
  }

  return (
    <>
      <GridItem minH={6} colSpan={3} justifySelf="flex-end" alignSelf="flex-start">
        {isEdit && (
          <Box overflow="hidden">
            <Icon
              as={FaTrashAlt}
              cursor="pointer"
              onClick={() => removeItem(skier, "skiPass", item.id)}
            />
          </Box>
        )}
      </GridItem>
      <GridItem fontSize="sm" alignSelf="flex-start">
        <SkiPassModal skier={skier} orderId={order.id} text={item.description} />
      </GridItem>
      <GridItem fontSize="sm" alignSelf="flex-start">
        -
      </GridItem>
      <GridItem fontSize="sm" alignSelf="flex-start">
        -
      </GridItem>
      <GridItem fontSize="sm" alignSelf="flex-start" justifySelf="flex-end">
        {asPrice(item.price.finalPrice)}
      </GridItem>
    </>
  );
}
